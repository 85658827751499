<template>
  <div class="signature">
    <div class="signature__title">
      <h1 class="large-title">Please select your company</h1>
    </div>
    <div class="signature__company">
      <div class="wrap">
        <p>Company</p>
        <select class="select" v-model="selectedCompany">
          <option
            v-for="company in companies"
            :key="company.id"
            :value="company.company"
          >
            {{ company.company }}
          </option>
        </select>
      </div>
      <button class="btn-regular primary" @click="submit">Submit</button>
      <button
        class="btn-regular primary"
        @click="getCompanies"
        v-if="showSignature"
      >
        Copy HTML
      </button>
    </div>
    <div>
      <p>
        <a
          href="#0"
          onclick="window.open('http://pii.or.id/uploads/dummies.pdf');"
          v-if="showSignature"
          >How to use in desktop</a
        >
      </p>
      <a
        href="#0"
        onclick="window.open('http://pii.or.id/uploads/dummies.pdf');"
        v-if="showSignature"
        >How to use in mobile</a
      >
    </div>
    <div
      class="signature__content"
      v-html="createStr()"
      v-if="showSignature && !showCode"
    ></div>
    <div class="signature__content" v-if="showCode">{{ showSignature }}</div>
    <!-- <span v-html="createStr()"></span> -->
  </div>
</template>

<script>
export default {
  name: "vue-signature",
  components: {},
  props: {
    user: Object,
  },
  data() {
    return {
      companies: [],
      selectedCompany: "",
      selectedDetailCompany: null,
      showSignature: "",
      showCode: false,
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    console.log(this.user);
    if (this.user == undefined) {
      this.$router.push({ name: "form" });
    }
    this.loadData();
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    async submit() {
      this.showSignature = "";
      const params = this.companies.find((s) => {
        return s.company == this.selectedCompany;
      });
      console.log(params);
      this.selectedDetailCompany = params;
      this.showSignature = true;
    },
    getCompanies() {
      this.$copyText(this.createStr()).then(
        function (e) {
          alert("Copied");
          console.log(e);
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    createStr() {
      const {
        salutation,
        firstName,
        name,
        position,
        email,
        phone,
        mobilePhone,
      } = this.$route.params.user;

      return `
      <!DOCTYPE html
          PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml">

      <head>
          <meta name="viewport" content="width=device-width">
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          <style type="text/css">

              @media only screen and (max-width:480px) {
              }

              @media only screen and (min-width:1025px) {
              }
          </style>
      </head>

      <body style="-webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding: 30px;">
          <p style="margin: 10px 0; font-size: 11px;">Mit freundlichen Gr&#xFC;&#xDF;en</p><br>
          <h5 style="margin:0; padding:0;font-size:18px;font-weight: 400; font-size: 11px;">${
            salutation !== null ? salutation : ""
          }</h5>
          <h5 style="margin-top:0; font-size:18px;font-weight: 400; margin-bottom: 10px; font-size: 11px;">${
            firstName !== null ? firstName : ""
          }</h5><br>
          <p style="margin-top:0; font-size:18px;font-weight: 600; font-size: 11px;">--</p>
          <h2 style="margin: 10px 0; font-size: 11px;">${
            this.selectedDetailCompany.companyName != null
              ? this.selectedDetailCompany.companyName
              : null
          }</h2>
          <br>
          <br>
          <img src="${
            this.selectedDetailCompany.companyLogo
          }" height="52" width="187" alt="Captrain_Logo" style="height: 52px; width: 187px;>
          <br>
          <br>
          <p style="margin: 4px 0; font-size: 11px;">${
            this.selectedDetailCompany.address
          }</p>
          <p style="margin: 4px 0; font-size: 11px;">Tel ${phone}</p>
          <p style="margin: 4px 0; font-size: 11px;">Mobil ${mobilePhone}</p>
          <p style="margin: 4px 0; font-size: 11px;">${email}</p>
          <div class="socials" style="display: flex;">
              <p style="margin: 32px 0;">
                  <h5 style="display: inline; margin-right: 16px; margin-bottom: 12px;line-height: 32px;"><a href="https://www.captrain.de/" style="text-decoration: none; color: black;">${
                    this.selectedDetailCompany.companyName
                  }</a></h5>
                  <a href="https://www.xing.com/pages/captraindeutschland"><img src="https://webdraft2020.com/email_templates/image007.png" alt="Xing_Logo" style="width:24px, height:24px;"></a>
                  <a href="https://www.kununu.com/de/captrain-deutschland"><img src="https://webdraft2020.com/email_templates/image006.png" alt="Xing_Logo" height="24" width="24" style="width:24px, height:24px;"></a>
                  <a href="https://www.linkedin.com/company/captraindeutschland/?originalSubdomain=de"><img src="https://webdraft2020.com/email_templates/image004.png" alt="Xing_Logo" height="24" width="24" style="width:24px, height:24px;"></a>
                  <a href="https://www.facebook.com/CaptrainDeutschland/"><img src="https://webdraft2020.com/email_templates/image008.png" alt="Xing_Logo" height="24" width="24" style="width:24px, height:24px;"></a>
                  <a href="https://www.instagram.com/captraindeutschland/"><img src="https://webdraft2020.com/email_templates/image005.png" alt="Xing_Logo" height="24" width="24" style="width:24px, height:24px;"></a>
              </p>
          </div>
          <p style="font-size: 11px;">
              ${this.selectedDetailCompany.namaPt}<br>
              ${this.selectedDetailCompany.founders}
          </p>
          <p style="margin:0; font-size:18px;font-weight: 600; font-size: 11px;">----------</p>
          <p style="font-size: 9px; margin: 0px 0px 4px 0;">
              Diese E-Mail enthält vertrauliche und/oder rechtlich geschützte Informationen.<br>
              This e-mail contains confidential and/or privileged information.
          </p>
          <p style="font-size: 9px;">
              Bitte beachten Sie unsere Datenschutzhinweise unter <a href="${
                this.selectedDetailCompany.linkPrivacy
              }" style="color: #bdcd00;text-decoration: none;">${
        this.selectedDetailCompany.linkPrivacy
      }</a>.<br> 
              Please note our data protection policy at <a href="${
                this.selectedDetailCompany.linkPrivacy
              }" style="color: #bdcd00;text-decoration: none;">${
        this.selectedDetailCompany.linkPrivacy
      }</a>
          </p>
      </body>

      </html>
      `;
    },
    async loadData() {
      try {
        const response = await this.axios.get("api/company");
        this.companies = response.data.data;
      } catch (error) {}
    },
    openFIle() {
      window.open(pdf, "_blank"); //to open in new tab
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>